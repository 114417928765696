<template>
  <div class="market">
    <watch-head :title="'BITNEY' + $t('市场')"
                :img="require('@/assets/bracelet_img/56.png')" />
    <div class="qiehuan">
      <div class="tab">
        <div class="item"
             :class="{'act': tabType == 0, 'vn-bold-italic': $i18n.locale == 'vi'}"
             @click="choice(0)">{{$t('探索')}}</div>
        <div class="item"
             :class="{'act': tabType == 1, 'vn-bold-italic': $i18n.locale == 'vi'}"
             @click="choice(1)">{{$t('拍卖')}}</div>
        <!-- <div class="item"
             :class="{'act': tabType == 2, 'vn-bold-italic': $i18n.locale == 'vi'}"
             @click="choice(2)">{{$t('拍卖')}}</div> -->
      </div>
    </div>
    <div class="screen">
      <div class="box">
        <div class="circle">
          <img src="@/assets/bracelet_img/54.png"
               alt="">
        </div>
        <div class="total" :class="{'vn-italic': $i18n.locale == 'vi'}">{{$t('总共')}} : {{count}} NFT</div>
      </div>
    </div>

    <div class="main">
      <van-search shape="round"
                  v-model="fromData.keyword"
                  left-icon=""
                  :placeholder="$t('请输入搜索关键词')"
                  :clearable="false"
                  class="search">
        <template #right-icon>
          <img class="right-icon"
               @click="search"
               src="@/assets/bracelet_img/55.png"
               mode="">
        </template>
      </van-search>
      <van-list v-model="loading"
                :finished="finished"
                :finished-text="$t('没有更多了')"
                :immediate-check="false"
                @load="getMarketplace"
                class="list">
        <div class="item"
             v-for="(item, index) in marketplaceList"
             :key="index">
          <div class="new">{{item.label || ''}}</div>
          <div class="name">{{item.name}}</div>
          <!-- <div class="cocobern">COCOBERN | LvL.{{item.task_no}}</div> -->
          <div class="cocobern">LvL.{{item.nft_grade}}</div>
          <img class="img"
               :src="item.cover"
               alt="">
          <div class="addr">
            <div class="tiao"></div>
            <!-- <div class="text">{{item.address || '暂无'}}</div> -->
            <div class="text">{{item.token_id}}</div>
            <div class="tiao"></div>
          </div>
          <div class="price">{{item.price}} BITNEY</div>

          <div v-if="newAuthorSolarixShow"
               @click="buy(item.price, item.online_order_id, item.id)"
               :class="{'vn-bold-italic': $i18n.locale == 'vi'}"
               class="buy">{{$t('购买')}}</div>
          <div v-else
               @click="newAuthorSolarix"
               :class="{'vn-bold-italic': $i18n.locale == 'vi'}"
               class="buy author">{{$t('授权')}}</div>
        </div>
      </van-list>

      <watch-foot />
    </div>
  </div>
</template>

<script>
import watchHead from '@/components/watchHead/index.vue'
import watchFoot from '@/components/watchFoot/index.vue'
import { MarketplaceApi, nftBuy } from '@/api/api'
import { mapState } from 'vuex'
//  轮播图插件  https://v1.github.surmon.me/vue-awesome-swiper/
export default {
  components: {
    watchHead,
    watchFoot
  },
  data () {
    return {
      tabType: 0,
      loading: false,
      finished: false,
      fromData: {
        page: 1,
        limit: 10,
        tab: 0,
        keyword: '',
        buy_coin_type:1,
      },
      marketplaceList: [],
      count: 0,
      value: '',

      authorSolarixShow: false,
      mySolarixBalance: 0,

      newAuthorSolarixShow: false,
    }
  },
  computed: {
    ...mapState([
      'web3', 'marketContract', 'marketContractAddress', 'bitneyAccuracy', 'ionzAccuracy', 'bitneyContract', 'ionzContract','newMarketContractAddress','newMarketContract'
    ]),
  },
  created () {
    this.waitForGlobal()
  },
  methods: {
    //购买
    buy (price, online_order_id, id, is_new_store) {
      if (this.mySolarixBalance < price) {
        this.$toast('bitney' + this.$t('余额不足')); //bitney 余额不足
        return;
      }
      let toast = this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: this.$t('购买中...'), //购买中...
      });
      nftBuy({ id: id, buy_coin_type:1, }).then(res => {
        if (res.code == 0) {
          this.newMarketContract.methods.buy(online_order_id).send({ from: localStorage.getItem('defaultAddress') }).then(() => {
            toast.clear();
            this.$toast(this.$t('购买成功')); //购买成功
          }).catch(() => {
            toast.clear();
            this.$toast(this.$t('购买失败')); //购买失败
          })
        } else {
          toast.clear();
          this.$toast(res.msg);
        }
      })
    },
    getBalance () {
      this.bitneyContract.methods.balanceOf(localStorage.getItem('defaultAddress')).call().then(res => {
        this.mySolarixBalance = res / Math.pow(10, this.bitneyAccuracy)
      })
    },
    //Bitney授权给新市场
    newAuthorSolarix () {
      let toast = this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: this.$t('授权中...'),
      });
      this.bitneyContract.methods.approve(
        this.newMarketContractAddress,
        '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
      ).send({ from: localStorage.getItem('defaultAddress') }).then((res) => {
        toast.clear();
        this.$toast(this.$t('授权成功'));//授权成功
        this.newAuthorSolarixShow = true
      }).catch(err => {
        toast.clear();
        this.$toast(this.$t('授权失败'));//授权失败
        this.newAuthorSolarixShow = false
      })
    },
    //查询Bitney是否需要授权给新市场
    newAllowanceSolarix () {
      this.bitneyContract.methods.allowance(localStorage.getItem('defaultAddress'), this.newMarketContractAddress).call({ from: localStorage.getItem('defaultAddress') }, (error, result) => {
        if (!error) {
          if (result == 0) {
            //需要授权
            this.newAuthorSolarixShow = false
          } else {
            //不需要授权 
            this.newAuthorSolarixShow = true
          }
        } else {
          console.log(error);
        }
      });
    },
    //链接钱包
    waitForGlobal: function () {
      window.clearTimeout(this.timer);
      // 1. 刷新页面时是否已经连接钱包登录
      if (localStorage.getItem('Token') && this.web3 && localStorage.getItem('defaultAddress')) {
        window.clearTimeout(this.timer);
        this.loading = true
        this.finished = false
        this.fromData.page = 1
        this.marketplaceList = []
        this.getMarketplace();
        this.newAllowanceSolarix();
        this.getBalance();
      } else {
        // 如果检测到没有连接钱包登录
        this.timer = setTimeout(
          this.waitForGlobal
          , 100);
      }
    },
    goTo (url) {
      this.$router.push({
        path: url
      })
    },
    choice (type) {
      if (this.tabType !== type) {
        this.tabType = type
        this.fromData.tab = type
        this.loading = true
        this.finished = false
        this.fromData.page = 1
        this.marketplaceList = []
        this.getMarketplace()
      }

    },
    /* 搜索 */
    search () {
      this.loading = true
      this.finished = false
      this.fromData.page = 1
      this.marketplaceList = []
      this.getMarketplace()
    },

    /* 获取市场列表 */
    getMarketplace () {
      if (this.finished) return
      this.loading = true;
      this.finished = false
      MarketplaceApi(this.fromData).then(res => {
        if (res.code == 0) {
          if (res.data.list.length < this.fromData.limit) {
            this.finished = true
          }
          this.count = res.count || 0
          this.marketplaceList = this.marketplaceList.concat(res.data.list)
          this.loading = false;
          this.fromData.page++
        }
      })
    },

  }
}
</script>

<style lang="less" scoped>
.market {
  width: 100%;
  min-height: 100vh;
  padding-top: 75px;
  padding-bottom: 60px;
  background-image: url(~@/assets/bracelet_img/8.png);
  background-repeat: repeat-y;
  background-size: 100% 100%;
  color: #ffffff;
  .qiehuan {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 20px 0 7px;
    .tab {
      width: 98%;
      background-color: rgba(43, 43, 43, 0.5);
      border-radius: 25px 0px 0px 3px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 6px;
      .item {
        width: 32%;
        text-align: center;
        cursor: pointer;
        // box-shadow: 2px 2px 1px 0px rgba(19, 19, 19, 0.16);
        border-radius: 20px 3px 20px 3px;
        font-family: 'REEJI-PinboGB-Flash';
        font-size: 21px;
        padding: 2px 0;
        text-align: center;
        color: #5c65cb;
      }
      .act {
        background-color: #a7f264;
        color: #2b2b2b;
      }
    }
  }
  .screen {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 11px;
    .box {
      // width: 200px;
      padding: 3px;
      background-color: rgba(53, 53, 53, 0.5);
      border-radius: 14px 0px 0px 14px;
      display: flex;
      align-items: center;
      font-family: 'TsangerYuYangT-W03';
      font-size: 10px;
      .circle {
        width: 24px;
        height: 24px;
        background-color: #a7f264;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 40px;
        img {
          width: 14px;
          height: 13px;
        }
      }
      .total {
        margin-right: 7px;
      }
    }
  }
  .main {
    margin-top: 15px;
    padding: 0 13px;
    .search {
      padding: 0;
      border-radius: 21px;
      margin-bottom: 22px;
      .van-field {
        padding: 10px 15px 10px 0;
      }
      /deep/.van-field__right-icon {
        display: flex;
        align-items: center;
      }
      .right-icon {
        width: 21px;
        height: 21px;
      }
    }
    .list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-between;
      max-height: 55vh;
      overflow-y: scroll;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10 */
      .item {
        width: 32%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #2b2b2b;
        border-radius: 8px;
        margin-bottom: 4px;
        margin-right: 6px;
        .new {
          width: 100%;
          background-image: linear-gradient(115deg, #a7f264 0%, #2b2b2b 95%),
            linear-gradient(#2b2b2b, #2b2b2b);
          background-blend-mode: normal, normal;
          padding: 2px 6px;
          margin-bottom: 7px;
          font-family: 'REEJI-PinboGB-Flash';
          font-size: 10px;
          color: #2b2b2b;
          border-radius: 8px 8px 0 0;
        }
        .name {
          font-family: 'AeroMaticsBoldItalic';
          font-size: 15px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          text-align: center;
          overflow: hidden;
        }
        .cocobern {
          font-family: 'AeroMaticsBold';
          font-size: 10px;
          color: #f4f2f4;
        }
        .img {
          width: 85px;
          height: 85px;
        }
        .addr {
          width: 100%;
          display: flex;
          align-items: center;
          margin: 5px 0;
          .tiao {
            width: 7px;
            min-width: 7px;
            height: 9px;
            background-color: #a7f264;
          }
          .text {
            width: 100%;
            font-family: 'AeroMaticsItalic';
            font-size: 10px;
            color: #ffffff;
            overflow: hidden;
            text-align: center;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
          }
        }
        .price {
          width: 100px;
          background-color: #ffffff;
          border-radius: 8px;
          padding: 2px 0;
          text-align: center;
          font-family: 'AeroMaticsDisplayItalic';
          font-size: 10px;
          color: #2b2b2b;
        }
        .buy {
          font-family: 'REEJI-PinboGB-Flash';
          font-size: 30px;
          text-align: center;
          color: transparent;
          background: -webkit-linear-gradient(
            left bottom,
            #38f8d4,
            #42e97f
          ); //#58FFD6
          /*  Safari 5.1 到 6.0 */
          background: -o-linear-gradient(top right, #38f8d4, #42e97f);
          /*  Opera 11.6 到 12.0 */
          background: -moz-linear-gradient(top right, #38f8d4, #42e97f);
          /*  Fx 3.6 到 15 */
          background: linear-gradient(to top right, #38f8d4, #42e97f);
          /* 标准语法（必须是最后一个） */
          background-clip: text;
          -webkit-background-clip: text;
        }
        .author{
          font-size: 16px;
        }
      }
      .item:nth-child(3n + 3) {
        margin-right: 0;
      }
    }
    .list::-webkit-scrollbar {
      display: none;
    }
  }
}
/deep/ .van-cell::after {
  border: 0;
}
/deep/ .van-list__finished-text {
  width: 100%;
}
/deep/ .van-list__loading {
  width: 100%;
}
</style>